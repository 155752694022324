<template>
  <section
    id="about-me-section"
    class="common-padding relative z-10 overflow-y-clip rounded-b-5xl bg-[#0d0d0f] text-flax-smoke-200 shadow-2xl will-change-auto sm:mt-0"
  >
    <div class="md:column-gap grid grid-cols-12">
      <div class="hide-on-mobile overflow-hidden md:col-span-4">
        <svg
          id="down-arrow-2"
          stroke="currentColor"
          fill="none"
          stroke-width="1.25"
          viewBox="6 6 12 12"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="hide-on-mobile m-0 size-20 -translate-x-full p-0"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="7" y1="7" x2="17" y2="17"></line>
          <polyline points="17 7 17 17 7 17"></polyline>
        </svg>
      </div>

      <h1
        id="little-bit-about-me"
        v-html="aboutMe"
        class="heading-1-alt lg:heading-1 section-heading col-span-full font-extrabold leading-none md:col-span-8 md:col-start-2"
      ></h1>
    </div>

    <div class="padding-y md:column-gap mt-6 grid grid-cols-12">
     
      <div class="col-span-11 mt-10 md:col-span-8 md:col-start-2">
        <p
          class="heading-4 relative w-full max-w-[40ch] text-balance font-medium leading-snug"
        >
          I have memory and remember everything. Based on this, I can help you by recalling past conversations, tracking details you've shared, and providing relevant suggestions. Whether it's code, project updates, or specific preferences, I can keep track of it all to assist you better.
        </p>

        <div
          class="mt-[5%] flex justify-start gap-10 text-flax-smoke-300 sm:gap-20"
        >
          <p class="heading-6 text-nowrap text-center text-flax-smoke-300/85">
            ( What memory I have )
          </p>
          <p class="heading-6 font-fancy w-full text-balance sm:max-w-[40ch]">
            By remembering your past interactions, work habits, and preferences, I’m able to maintain context and provide better, more tailored support. Whether it's optimizing your workflow, offering relevant suggestions, or helping with specific tasks, I can anticipate your needs based on the details you’ve shared over time. This allows me to contribute positively to your work by enhancing efficiency, minimizing repetition, and providing solutions that fit your style and goals, both in your professional and personal endeavors.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import {
    animateSplitText,
    xToZero,
    animateAboutMeSectionLeave,
  } from '@/animations';
  import { onMounted, ref } from 'vue';

  const aboutMe = ref('I know');

  // const initialPath = ref(`M0 0H${width.value}  V${height.value} H0 Z`);
  // const targetPath = ref(
  //   `M0 0H${width.value}L${width.value * 0.9} ${height.value}H${width.value * 0.1}L0 0Z`,
  // );
  onMounted(() => {
    animateSplitText(
      '#little-bit-about-me .letters',
      '#little-bit-about-me',
      1,
      0.01,
      0,
      () => {
        xToZero('#down-arrow-2');
      },
    );

    animateAboutMeSectionLeave('#about-me-section');
  });
</script>
