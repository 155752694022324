<template>
  <section id="works" class="common-padding mb-20">
    <div
      class="sm:column-gap relative mt-12 grid size-full grid-cols-12 lg:mt-[10%]"
    >
      <div
        style="top:30%"
        class="sticky col-span-5 hidden h-fit w-full overflow-hidden text-[20vw] font-semibold leading-[0.8] text-flax-smoke-100 md:flex"
      >
        <span class="relative font-title! -tracking-wider">I can</span>
      </div>
      <aside
        class="relative col-span-full flex flex-col space-y-10 md:col-span-7"
      >
        <div
          v-for="(work, i) in selectedWorksProps"
          :key="i"
          class="@container work-card"
        >
          <a class="group" target="_blank" :href="work.url" style="position: relative;">
            <div
              class="flex-center relative aspect-square overflow-clip rounded-a-5xl"
            >
              <img
                alt="work-background"
                loading="lazy"
                class="absolute size-full select-none object-cover"
                style="filter: opacity(0.5);"
                :src="work.imageBg"
              />
              <div
                class="flex-center z-10 aspect-4/3 size-full overflow-clip rounded-a-5xl object-cover"
                 v-if="work.videoSrc"
              >
                <!-- autoplay="false" -->
                <video
                  :src="work.videoSrc"
                  loop
                  muted
                  @error="handleVideoError"
                  type="video/webm"
                  class="work-video size-[80%] rounded-md object-contain"
                ></video>
              </div>
            </div>
            <div style="position: absolute; z-index: 1; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; width: 80%;">
              <p class="heading-6 mb-[1%] mt-[2%] font-title! leading-none">
                {{ work.category }}
              </p>
              <div>
                <h3 class="heading-3 font-title! font-bold">
                  {{ work.name }}
                </h3>
              </div>
            </div>
          </a>
        </div>
      </aside>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { animateSplitText } from '@/animations';
  // import { work1, work2, work3, work4 } from '@/assets/videos';
  import { computed, onMounted, ref } from 'vue';
  import gsap from 'gsap';
  import { workBg1, workBg2, workBg3, workBg4 } from '@/assets/images';
  import { useWindowSize } from '@vueuse/core';

  const isSmallScreen = computed(() => {
    return useWindowSize().width.value < 768;
  });
  const index = ref(0);

  const selectedWorksProps = [
    {
      id: 0,
      name: 'at your favorite restaurant',
      category: 'Make reservation',
      videoSrc: null,
      imageBg: workBg1,
      url: 'https://github.com/aura/pyutube',
    },
    {
      id: 1,
      name: 'such as when choosing the shortest route',
      category: 'Help with daily tasks',
      videoSrc: null,
      imageBg: workBg2,
      url: 'https://github.com/Aura/iphone15-pro-clone',
    },
    {
      id: 2,
      name: 'is what you/\'ll keep coming back to',
      category: 'Remember for you',
      videoSrc: null,
      imageBg: workBg3,
      url: 'https://github.com/Aura/axon',
    },
    {
      id: 3,
      name: 'to your old customers',
      category: 'Send emails',
      videoSrc: null,
      imageBg: workBg4,
      url: 'https://github.com/Aura/blog',
    },
  ];

  const handleVideoError = (event: any) => {
    console.error('Video failed to load:', event);
  };

  // Reusable function to handle forward scroll animation
  const createForwardTimeline = (
    index: any,
    i: any,
    selectedWorksProps: any[],
  ) => {
    const tl = gsap.timeline({
      defaults: { duration: 0.3 },
    });

    // Set and move the #index element
    tl.set('#index', {
      yPercent: 100,
      onComplete: () => {
        index.value = Math.min(i, selectedWorksProps.length - 1);
      },
    }).to('#index', {
      yPercent: 0,
      ease: 'power1.inOut',
    });

    return tl;
  };

  // Reusable function to handle backward scroll animation
  const createBackwardTimeline = (index: any, i: any) => {
    const tl = gsap.timeline({ defaults: { duration: 0.3 } });

    // Set and move the #index element
    tl.set('#index', {
      yPercent: -100,
      onComplete: () => {
        index.value = Math.max(i, 0);
      },
    }).to('#index', {
      yPercent: 0,
      duration: 0.3,
      ease: 'power1.inOut',
    });

    return tl;
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const video = entry.target as HTMLVideoElement;
      if (entry.isIntersecting) {
        video.play();
      }
    });
  };

  onMounted(() => {
    const workVideos = document.querySelectorAll('.work-video');

    // Create the IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.25, // Trigger when 25% of the video is visible
    });

    // Observe each video element
    workVideos.forEach((video) => {
      observer.observe(video);
    });

    animateSplitText(
      '#selectedWorks .letters',
      '#selected-works-text',
      0.7,
      0.01,
      0,
    );

    // Apply GSAP animations to each div
    if (!isSmallScreen.value)
      gsap.utils.toArray('.work-card').forEach((div: any, i: any) => {
        gsap.timeline({ defaults: { duration: 0.7 } }).to(div, {
          scrollTrigger: {
            trigger: div,
            // start: 'top 40%',
            start: 'top 25%',
            // end: 'bottom 40%',
            end: 'bottom 25%',
            scrub: 0.01,
            // markers: true,
            onLeaveBack: () => {
              // Backward scroll animation
              if (index.value !== 0) {
                gsap.to('#index', {
                  yPercent: 100,
                  duration: 0.3,
                  ease: 'power4.inOut',
                  onComplete: () => {
                    createBackwardTimeline(index, i - 1);
                  },
                });
              }
            },
          },
          ease: 'power1.inOut',
          onComplete: () => {
            // Forward scroll animation
            if (index.value !== selectedWorksProps.length - 1) {
              gsap.to('#index', {
                yPercent: -100,
                duration: 0.3,
                ease: 'power4.inOut',
                onComplete: () => {
                  createForwardTimeline(index, i + 1, selectedWorksProps);
                },
              });
            }
          },
        });
      });
  });
</script>
