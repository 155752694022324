<template>
  <BurgerMenuBtn
    class="z-9999 scale-0 drop-shadow-lg"
    style="top: 0;"
    id="burger"
  />

  <header
    :class="$attrs.class"
    class="padding-x absolute inset-0 z-20 h-fit -translate-y-full pt-6 will-change-auto"
  >
    <nav class="flex justify-between">
      <MagneticEffect
        :magnetoStrengthVal="20"
        :magnetoTextStrengthVal="10"
        divId="name-container"
        textId="name"
      >
        <div id="name-container" class="group -m-10 h-fit cursor-pointer p-10">
          <AnimatedLogo
            id="name"
            class="logotype-headline"
            width="50px"
            height="50px"
            fill="#fff"
          />
        </div>
      </MagneticEffect>

      <div class="flex" style="align-items: anchor-center;">
        <ul
          class="w-full flex-1 gap-1 overflow-y-hidden text-lg font-medium md:flex md:gap-2 md:text-xl lg:gap-4 lg:text-2xl xl:text-3xl text-flax-smoke-200"
        >
          <Link
            v-for="(l, index) in navLinks"
            :key="l.label"
            tag="li"
            :label="l.label + (index !== navLinks.length - 1 ? ',' : '')"
            :url="l.url"
          />
        </ul>
      </div>
    </nav>
  </header>
</template>
<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';

  import { Link, BurgerMenuBtn, MagneticEffect, AnimatedLogo } from '..';

  import {
    navbarScale,
  } from '@/animations';
  import { navLinks } from '@/data';

  const isNavbarOpen = ref(false);

  onMounted(() => {
    navbarScale('#burger', '#hero');
  });

  const emit = defineEmits(['isLocked']);
  watch(isNavbarOpen, (newVal) => {
    emit('isLocked', newVal);
  });
</script>
.