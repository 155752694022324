// Nav type
export type navLinkType = {
  label: string;
  url: string;
};

// Nav
const navLinks = [
  {
    label: 'device',
    url: '#services',
  },
  {
    label: 'tech details',
    url: '#works',
  },
  {
    label: 'gloria',
    url: '#about-me-section',
  },
  {
    label: 'accessories',
    url: '#testimonials-section',
  },
  {
    label: 'support',
    url: '#contact-section',
  },
];
const navbarLinks = [
  {
    label: 'Home',
    url: '#app',
  },
  ...navLinks,
];

const socialLinks = [
  {
    label: 'X',
    url: 'https://x.com/mariuszmalek',
  },
  {
    label: 'GitHub',
    url: 'https://github.com/aura',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/aura/',
  },
];

// cal.com
const dataCalNamespace = '30min';
const dataCalLink = 'auraxx/30min';
const dataCalConfig =
  '{"layout":"month_view", "theme": "dark", "brand": "#c1d4db"}';

export {
  socialLinks,
  navLinks,
  navbarLinks,
  dataCalNamespace,
  dataCalLink,
  dataCalConfig,
};
