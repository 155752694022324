<template>
  <section id="services" class="padding-x mb-20">
    <div class="flex w-full flex-col">
      <h1
        id="what-i-do"
        v-html="whatIDo"
        class="heading-1 font-extrabold"
      ></h1>

      <div
        id="services-text"
        class="grid-gap grid grid-cols-12 justify-end text-flax-smoke-300 opacity-0 lg:grid"
      >
        <p
          class="heading-4 font-fancy col-span-8 w-full text-balance sm:font-semibold md:col-span-5"
        >
          My destiny, is to automate human labor and provide conditions that make life easier.
        </p>
      </div>
    </div>

    <div class="relative mt-12 w-full lg:mt-[10%]">
      <div class="mt-12 flex flex-col justify-between gap-y-16">
        <ServicesCard
          v-for="(card, index) in servicesCardProps"
          :key="index"
          :number="index + 1"
          :title="card.title"
          :body="card.body"
          :headings="card.headings"
          :shape="card.shape"
          class="sticky border-t border-flax-smoke-500/50 bg-[#0d0d0f]"
          :class="getStyle(index)"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { animateSplitText } from '@/animations';
  import { textSplitterIntoChar } from '@/functions';
  import { onBeforeMount, onMounted, ref } from 'vue';
  import { ServicesCard } from '..';

  import Circle1 from '@/components/design/icons/Circle1.vue';
  import Circle2 from '@/components/design/icons/Circle2.vue';
  import Circle3 from '@/components/design/icons/Circle3.vue';

  const whatIDo = ref('I am Aura');

  const servicesCardProps = [
    {
      title: 'Maximum Privacy and Security',
      body: 'I operate entirely offline, requiring no internet connection. All data stays securely within me, never transmitted or exposed to external networks. This ensures maximum privacy and protection. Independent and reliable, I work anywhere, even without internet access. With no risk of unauthorized access or tracking, I guarantee complete security and peace of mind.',
      headings: [
        '100% security',
        '100% privacy',
        '100% offline',
      ],
      shape: Circle1,
    },
    {
      title: 'No APIs, No Data Leaks',
      body: 'I operate entirely on the device, processing everything locally without external servers or cloud APIs. No data is transmitted or stored elsewhere, ensuring complete privacy and security. With no external connections, there’s no risk of eavesdropping, surveillance, or unauthorized access—your information stays fully under your control.',
      headings: [
        'Own LLM',
        'AI inside device',
        'Database inside device',
      ],
      shape: Circle2,
    },
    {
      title: 'Gloria OS',
      body: 'I work seamlessly across various platforms and devices, integrating effortlessly into your life. Whether its your phone, smartwatch, car, or smart home, I ensure smooth interaction and compatibility. I connect with everything, giving you control and monitoring from any device. Built to adapt, I provide a unified and personalized experience, keeping you connected and in control wherever you are.',
      headings: [
        'Smartphones',
        'Tablets',
        'Computers',
        'Smart TV',
        'Smart Watch',
        'Smart Glasses',
        'Smart Home',
        'Smart Car',
      ],
      shape: Circle3,
    },
  ];

  onBeforeMount(() => {
    whatIDo.value = textSplitterIntoChar('I am Aura', true);
  });

  onMounted(() => {
    animateSplitText('#what-i-do .letters', '#services-text', 0.7, 0.01, 0);
  });

  const getStyle = (index: number) => {
    if (index === 0) {
      return 'top-[calc(20vh_+_0em)] mb-[17.25em]';
    }

    if (index === 1) {
      return 'top-[calc(20vh_+_5.75em)] mb-[11.5em]';
    }

    if (index === 2) {
      return 'top-[calc(20vh_+_11.5em)] mb-[5.75em]';
    }
  };
</script>
