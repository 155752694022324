<template>
  <svg
    style="width: var(--heading-display)"
    viewBox="0 0 55 37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.2949 2.11614L35.9286 1.74986L35.4107 1.75L24.2607 1.753L21.2423 1.75381L23.3775 3.88724L32.4356 12.9381L11.7961 12.939H11.7959L3.00014 12.938L1.75 12.9379V14.188V22.991V24.241H3H32.4361L23.3764 33.2959L21.2405 35.4305L24.2602 35.43L35.4102 35.428L35.9278 35.4279L36.2938 35.0619L51.8838 19.4739L52.7678 18.5901L51.8839 17.7061L36.2949 2.11614Z"
      class="fill-current stroke-current stroke-[2.5]"
    />
  </svg>
</template>
