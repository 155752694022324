<template>
  <svg
    style="width: var(--heading-display)"
    viewBox="0 0 102 102"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999042 53.5H1.00096L44.9238 53.5168L13.8776 84.5869L13.8762 84.5882L17.4118 88.1238L17.4131 88.1224L17.4149 88.1206L48.4832 57.0761L48.5 100.999V101.001H53.5V100.999L53.5168 57.0761L84.5869 88.1224L84.5882 88.1238L88.1238 84.5882L88.1224 84.5869L57.0761 53.5168L100.999 53.5H101.001V48.5H100.999L57.0761 48.4832L88.1206 17.4149L88.1224 17.4131L88.1238 17.4118L84.5882 13.8762L84.5869 13.8776L53.5168 44.9238L53.5 1.00096V0.999042H48.5V1.00096L48.4832 44.9238L17.4131 13.8776L17.4118 13.8762L13.8762 17.4118L13.8776 17.4131L44.9238 48.4832L1.00096 48.5H0.999042V53.5Z"
      class="fill-current stroke-current stroke-[2.5]"
    />
  </svg>
</template>
